var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-bg" },
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "review-body" },
        [
          _c("TopBar"),
          _c(
            "div",
            { staticClass: "filter-bar boxarea topic-filter" },
            [
              _c("SelectLayer"),
              _c("div", [
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.loading },
                    on: { click: _vm.handleMessage }
                  },
                  [
                    _vm.loading
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _vm._e(),
                    _vm._v(" 리뷰 상세 보기")
                  ]
                ),
                _c("button", { on: { click: _vm.handleTopic } }, [
                  _vm._v(_vm._s(_vm.$t("set_topic")))
                ])
              ]),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTopic,
                      expression: "isTopic"
                    }
                  ]
                },
                [
                  _c("li", [
                    _c("label", [
                      _c("input", {
                        attrs: { type: "checkbox", checked: "" },
                        on: {
                          change: function($event) {
                            return _vm.handleSelectAll($event)
                          }
                        }
                      }),
                      _vm._v("Check All ")
                    ])
                  ]),
                  _vm._l(_vm.predictList, function(data, i) {
                    return _c("li", { key: i }, [
                      _c("label", [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked:
                              _vm.checkItems.indexOf(data._id.aspectIndex) > -1
                                ? true
                                : false
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleSelect(
                                $event,
                                data._id.aspectIndex
                              )
                            }
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              data._id.aspectIndex === 1
                                ? "General"
                                : data._id.aspectIndex === 2
                                ? "Size"
                                : data._id.aspectIndex === 3
                                ? "Material"
                                : data._id.aspectIndex === 4
                                ? "Quality"
                                : data._id.aspectIndex === 5
                                ? "Color"
                                : data._id.aspectIndex === 6
                                ? "Price"
                                : data._id.aspectIndex === 7
                                ? "Picture"
                                : data._id.aspectIndex === 8
                                ? "Style"
                                : data._id.aspectIndex === 9
                                ? "Brand"
                                : data._id.aspectIndex === 10
                                ? "Shipping"
                                : data._id.aspectIndex === 11
                                ? "Durability"
                                : data._id.aspectIndex === 12
                                ? "Problem"
                                : null
                            ) +
                            " "
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "product-insight" },
            _vm._l(_vm.predictList, function(data, i) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.checkItems.indexOf(data._id.aspectIndex) >= 0
                          ? true
                          : false,
                      expression:
                        "checkItems.indexOf(data._id.aspectIndex) >= 0 ? true : false"
                    }
                  ],
                  key: i,
                  staticClass: "boxarea"
                },
                [
                  _c("div", { staticClass: "box-title" }, [
                    _c("h2", [
                      data._id.aspectIndex === 1
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-1.png")
                            }
                          })
                        : data._id.aspectIndex === 2
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-7.png")
                            }
                          })
                        : data._id.aspectIndex === 3
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-3.png")
                            }
                          })
                        : data._id.aspectIndex === 4
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-2.png")
                            }
                          })
                        : data._id.aspectIndex === 5
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-6.png")
                            }
                          })
                        : data._id.aspectIndex === 6
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-4.png")
                            }
                          })
                        : data._id.aspectIndex === 7
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-7.png")
                            }
                          })
                        : data._id.aspectIndex === 8
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-8.png")
                            }
                          })
                        : data._id.aspectIndex === 9
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-9.png")
                            }
                          })
                        : data._id.aspectIndex === 10
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-1.png")
                            }
                          })
                        : data._id.aspectIndex === 11
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-9.png")
                            }
                          })
                        : data._id.aspectIndex === 12
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/reviewmind/insight-icon-9.png")
                            }
                          })
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            data._id.aspectIndex === 1
                              ? "General"
                              : data._id.aspectIndex === 2
                              ? "Size"
                              : data._id.aspectIndex === 3
                              ? "Material"
                              : data._id.aspectIndex === 4
                              ? "Quality"
                              : data._id.aspectIndex === 5
                              ? "Color"
                              : data._id.aspectIndex === 6
                              ? "Price"
                              : data._id.aspectIndex === 7
                              ? "Picture"
                              : data._id.aspectIndex === 8
                              ? "Style"
                              : data._id.aspectIndex === 9
                              ? "Brand"
                              : data._id.aspectIndex === 10
                              ? "Shipping"
                              : data._id.aspectIndex === 11
                              ? "Durability"
                              : data._id.aspectIndex === 12
                              ? "Problem"
                              : null
                          ) +
                          " "
                      )
                    ]),
                    _c("h2", [
                      _vm._v(
                        _vm._s(data.reviewTotal) +
                          " " +
                          _vm._s(_vm.$t("review_sentence"))
                      )
                    ])
                  ]),
                  _c("HorizontalBar", {
                    attrs: { series: data, reviews: data.reviewTotal }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.modalVisible
          ? _c(
              "div",
              { staticClass: "dim" },
              [
                _c("ReviewDetail3", {
                  attrs: { reviewList: _vm.reviewList },
                  on: { close: _vm.close }
                }),
                _c("div", { staticClass: "dimbg", on: { click: _vm.close } })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }