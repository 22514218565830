<template>
  <div class="review-bg">
    <Navbar />
    <div class="review-body">
      <TopBar />
      <div class="filter-bar boxarea topic-filter">
        <SelectLayer />
        <div>
          <button @click="handleMessage" :disabled="loading"><i v-if="loading" class="el-icon-loading"></i> 리뷰 상세 보기</button>
          <button @click="handleTopic">{{ $t("set_topic") }}</button>
        </div>
        <ul v-show="isTopic">
          <li>
            <label> <input type="checkbox" checked @change="handleSelectAll($event)" />Check All </label>
          </li>
          <li v-for="(data, i) in predictList" :key="i">
            <label>
              <input type="checkbox" :checked="checkItems.indexOf(data._id.aspectIndex) > -1 ? true : false" @change="handleSelect($event, data._id.aspectIndex)" />
              {{
                data._id.aspectIndex === 1
                  ? "General"
                  : data._id.aspectIndex === 2
                  ? "Size"
                  : data._id.aspectIndex === 3
                  ? "Material"
                  : data._id.aspectIndex === 4
                  ? "Quality"
                  : data._id.aspectIndex === 5
                  ? "Color"
                  : data._id.aspectIndex === 6
                  ? "Price"
                  : data._id.aspectIndex === 7
                  ? "Picture"
                  : data._id.aspectIndex === 8
                  ? "Style"
                  : data._id.aspectIndex === 9
                  ? "Brand"
                  : data._id.aspectIndex === 10
                  ? "Shipping"
                  : data._id.aspectIndex === 11
                  ? "Durability"
                  : data._id.aspectIndex === 12
                  ? "Problem"
                  : null
              }}
            </label>
          </li>
        </ul>
      </div>
      <section class="product-insight">
        <div v-show="checkItems.indexOf(data._id.aspectIndex) >= 0 ? true : false" class="boxarea" v-for="(data, i) in predictList" :key="i">
          <div class="box-title">
            <h2>
              <img v-if="data._id.aspectIndex === 1" src="@/assets/images/reviewmind/insight-icon-1.png" />
              <img v-else-if="data._id.aspectIndex === 2" src="@/assets/images/reviewmind/insight-icon-7.png" />
              <img v-else-if="data._id.aspectIndex === 3" src="@/assets/images/reviewmind/insight-icon-3.png" />
              <img v-else-if="data._id.aspectIndex === 4" src="@/assets/images/reviewmind/insight-icon-2.png" />
              <img v-else-if="data._id.aspectIndex === 5" src="@/assets/images/reviewmind/insight-icon-6.png" />
              <img v-else-if="data._id.aspectIndex === 6" src="@/assets/images/reviewmind/insight-icon-4.png" />
              <img v-else-if="data._id.aspectIndex === 7" src="@/assets/images/reviewmind/insight-icon-7.png" />
              <img v-else-if="data._id.aspectIndex === 8" src="@/assets/images/reviewmind/insight-icon-8.png" />
              <img v-else-if="data._id.aspectIndex === 9" src="@/assets/images/reviewmind/insight-icon-9.png" />
              <img v-else-if="data._id.aspectIndex === 10" src="@/assets/images/reviewmind/insight-icon-1.png" />
              <img v-else-if="data._id.aspectIndex === 11" src="@/assets/images/reviewmind/insight-icon-9.png" />
              <img v-else-if="data._id.aspectIndex === 12" src="@/assets/images/reviewmind/insight-icon-9.png" />
              {{
                data._id.aspectIndex === 1
                  ? "General"
                  : data._id.aspectIndex === 2
                  ? "Size"
                  : data._id.aspectIndex === 3
                  ? "Material"
                  : data._id.aspectIndex === 4
                  ? "Quality"
                  : data._id.aspectIndex === 5
                  ? "Color"
                  : data._id.aspectIndex === 6
                  ? "Price"
                  : data._id.aspectIndex === 7
                  ? "Picture"
                  : data._id.aspectIndex === 8
                  ? "Style"
                  : data._id.aspectIndex === 9
                  ? "Brand"
                  : data._id.aspectIndex === 10
                  ? "Shipping"
                  : data._id.aspectIndex === 11
                  ? "Durability"
                  : data._id.aspectIndex === 12
                  ? "Problem"
                  : null
              }}
            </h2>
            <h2>{{ data.reviewTotal }} {{ $t("review_sentence") }}</h2>
            <!-- <button @click="handleReviews(0,data._id.aspectIndex)">Neutral</button>
            <button @click="handleReviews(1,data._id.aspectIndex)">Positive</button>
            <button @click="handleReviews(2,data._id.aspectIndex)">Negative</button>-->
          </div>
          <HorizontalBar :series="data" :reviews="data.reviewTotal" />
        </div>
      </section>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <ReviewDetail3 @close="close" :reviewList="reviewList" />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
import HorizontalBar from "@/components/charts/HorizontalBar";
import Navbar from "@/components/Navbar";
import TopBar from "@/components/dashboard/TopBar";
import SelectLayer from "@/components/dashboard/SelectLayer";
import { fetchProductInsight, fetchAsprectReviews } from "@/api/review";
import ReviewDetail3 from "@/components/pop/ReviewDetail3";
export default {
  components: { TopBar, Navbar, SelectLayer, HorizontalBar, ReviewDetail3 },

  data() {
    return {
      since: "6", // default 기간 설정
      predictList: [],
      isTopic: false,
      tempSeries: [],
      input: [],
      reviewTotal: 0,
      checkItems: [],
      selectList: [],
      reviewList: [],
      modalVisible: false,
      contactVisible: false,
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("DASHBOARD_MENU", 3);
  },
  mounted() {
    this.getReviewScore();
  },
  computed: {
    ...mapState(["navVisible", "footerVisible", "term", "asin", "type", "url", "productName"]),
  },
  watch: {
    term: function() {
      this.getReviewScore();
    },
  },
  methods: {
    close() {
      this.modalVisible = false;
    },
    handleMessage() {
      this.loading = true;
      this.handleReviews();
    },
    handleReviews() {
      let params = {
        productName: this.productName,
      };
      fetchAsprectReviews(params).then((res) => {
        if (res.data.status == 200) {
          this.reviewList = res.data.data;
          this.modalVisible = true;
        } else {
          return alert(res.data.message);
        }
        this.loading = false;
      });
    },
    handleSelectAll($event) {
      if ($event.target.checked) {
        const idArray = [];
        this.selectList.forEach((item) => idArray.push(item));
        this.checkItems = idArray;
      } else {
        this.checkItems = [];
      }
    },
    handleSelect(e, idx) {
      if (e.target.checked) {
        this.checkItems = [...this.checkItems, idx];
      } else {
        this.checkItems = this.checkItems.filter((id) => idx !== id);
      }
    },
    handleTopic() {
      this.isTopic = !this.isTopic;
    },
    getReviewScore() {
      let params = {
        asin: this.asin,
        term: this.term,
      };
      this.predictList = [];
      this.checkItems = [];
      fetchProductInsight(params).then((res) => {
        if (res.status == 200) {
          this.predictList = res.data.data;
          res.data.data.forEach((item) => {
            /**
             * TODO : aspectIndex 13인경우 별도 처리 요망!
             */
            if (item._id.aspectIndex < 13) {
              this.selectList.push(item._id.aspectIndex);
            }
          });
          this.checkItems = this.selectList;
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
  beforeDestroy() {
    this.$store.dispatch("SET_ASIN", "");
    this.$store.dispatch("SET_URL", "");
    this.$store.dispatch("VIEW_TERM", "");
  },
};
</script>
<style>
.el-progress-bar {
  width: 80%;
  margin-top: 15px;
}
</style>
