var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      _vm._l(_vm.predicts, function(item, i) {
        return _c("li", { key: i, staticClass: "p-insight-progress" }, [
          item.absa != null
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.absa == 0
                            ? "Neutral"
                            : item.absa == 1
                            ? "Positive"
                            : item.absa == 2
                            ? "Negative"
                            : ""
                        ) +
                        " "
                    )
                  ]),
                  _c("el-progress", {
                    attrs: {
                      "text-inside": true,
                      "stroke-width": 20,
                      percentage: Math.round((item.total / _vm.reviews) * 100),
                      color:
                        item.absa == 0
                          ? _vm.customColor1
                          : item.absa == 1
                          ? _vm.customColor2
                          : item.absa == 2
                          ? _vm.customColor3
                          : "#FFFFFF"
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(item.total))])
                ],
                1
              )
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }