<template>
  <div class="ReviewDetail modal">
    <div class="modal_cover" @click="close"></div>
    <div class="modal_content">
      <div class="m_header">
        <div>{{ productName }} 제품 리뷰 상세 데이터</div>
        <button @click="close" class="close">
          <span class="material-icons"> close </span>
        </button>
      </div>

      <div class="m_body">
        <div class="buttonWrap">
          <button
            :class="{ active: aspectIndex == 1 }"
            @click="handleTab('one')"
          >
            General
          </button>
          <button
            :class="{ active: aspectIndex == 2 }"
            @click="handleTab('two')"
          >
            Size
          </button>
          <button
            :class="{ active: aspectIndex == 3 }"
            @click="handleTab('three')"
          >
            Material
          </button>
          <button
            :class="{ active: aspectIndex == 4 }"
            @click="handleTab('four')"
          >
            Quality
          </button>
          <button
            :class="{ active: aspectIndex == 5 }"
            @click="handleTab('five')"
          >
            Color
          </button>
          <button
            :class="{ active: aspectIndex == 6 }"
            @click="handleTab('six')"
          >
            Price
          </button>
          <button
            :class="{ active: aspectIndex == 7 }"
            @click="handleTab('seven')"
          >
            Picture
          </button>
          <button
            :class="{ active: aspectIndex == 8 }"
            @click="handleTab('eight')"
          >
            Style
          </button>
          <button
            :class="{ active: aspectIndex == 9 }"
            @click="handleTab('nine')"
          >
            Brand
          </button>
          <button
            :class="{ active: aspectIndex == 10 }"
            @click="handleTab('ten')"
          >
            Shipping
          </button>
          <button
            :class="{ active: aspectIndex == 11 }"
            @click="handleTab('eleven')"
          >
            Durability
          </button>
          <button
            :class="{ active: aspectIndex == 12 }"
            @click="handleTab('twelve')"
          >
            Problem
          </button>
        </div>

        <div class="reviewList">
          <div>
            <table
              class="tr6"
              v-if="
                reviewList.filter((data) => data.aspect_Index == aspectIndex)
                  .length > 0
              "
            >
              <tr>
                <th>번호</th>
                <th>날짜</th>
                <th>리뷰 소스</th>
                <th>리뷰 문장</th>
                <th>별점</th>
                <th>감성분석</th>
              </tr>
              <tr
                v-for="(data, i) in reviewList.filter(
                  (data) => data.aspect_Index == aspectIndex
                )"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ moment(data.review_date).format("YYYY.MM.DD") }}</td>
                <td>{{ data.review_source }}</td>
                <td>{{ data.sentence }}</td>
                <td>{{ data.rating }}</td>
                <td>
                  {{
                    data.ABSA_predict == 0
                      ? "Negative"
                      : data.ABSA_predict == 1
                      ? "Positive"
                      : data.ABSA_predict == 2
                      ? "Neutral"
                      : ""
                  }}
                </td>
              </tr>
            </table>

            <div class="nodata" v-else>데이터가 없습니다.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  createProduct,
  updateProduct,
  fetchProductList,
  deleteProduct,
} from "@/api/product";
import { createModelData } from "@/api/review";
// import { eventBus } from "@/main";
import { mapState } from "vuex";
import moment from "moment";
export default {
  props: {
    reviewList: {
      type: Array,
    },
  },
  data() {
    return {
      moment: moment,
      visible: false,
      type: "",
      asin: "",
      competitorList: [],
      competitorAsin: [],
      CompetitorProduct: [],
      CompetitorProductName: "",
      produtNameList: [],
      productUrlList: [],
      competitorUrlList: [],
      productList: [],
      urlList: [], //저장을 위한 제품 url 배열
      comUrlList: [], //저장을 위한 경쟁사 제품 url 배열
      activeNames: ["1"],
      index: 0,
      aspectIndex: 1,
    };
  },
  mounted() {
    this.getProductInfo();
  },
  computed: {
    ...mapState(["userInfo", "productName"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    // closeModal() {
    //   eventBus.$emit("closeModal", true);
    // },
    openModal() {
      this.$emit("openModal");
    },
    handleTab(tab) {
      if (tab == "one") {
        this.aspectIndex = 1;
      } else if (tab == "two") {
        this.aspectIndex = 2;
      } else if (tab == "three") {
        this.aspectIndex = 3;
      } else if (tab == "four") {
        this.aspectIndex = 4;
      } else if (tab == "five") {
        this.aspectIndex = 5;
      } else if (tab == "six") {
        this.aspectIndex = 6;
      } else if (tab == "seven") {
        this.aspectIndex = 7;
      } else if (tab == "eight") {
        this.aspectIndex = 8;
      } else if (tab == "nine") {
        this.aspectIndex = 9;
      } else if (tab == "ten") {
        this.aspectIndex = 10;
      } else if (tab == "eleven") {
        this.aspectIndex = 11;
      } else if (tab == "twelve") {
        this.aspectIndex = 12;
      }
    },
    removeProduct(_id) {
      let result = confirm(
        "자사 제품을 삭제시 경쟁사 제품도 삭제가 됩니다. 삭제 하시겠습니까?"
      );
      if (result == false) {
        return;
      }
      deleteProduct(_id).then((res) => {
        if (res.data.status == 200) {
          this.closeModal();
          window.document.location.href = window.document.location;
        } else {
          return alert(res.data.message);
        }
      });
    },

    handleAddUrl() {
      if (this.type == "amazon") {
        if (this.produtNameList.length == 5) {
          return alert("최대 5개 까지만 url을 등록 할수 있습니다.");
        }
        let data = {
          productName: "",
        };
        this.produtNameList.push(data);
      } else {
        if (this.urlList.length == 5) {
          return alert("최대 5개 까지만 제품을 등록 할수 있습니다.");
        }
        let data = {
          url: "",
        };
        this.urlList.push(data);
      }
    },
    handleMinusUrl(i) {
      if (this.urlList.length == 0) {
        return;
      }
      this.urlList.splice(i, 1);
    },
    handleAddCompetitor() {
      if (this.competitorList.length > 2) {
        return alert("최대 3개 까지만 url을 등록 할수 있습니다.");
      }
      let data = {
        asin: "",
        productName: "",
      };
      this.competitorList.push(data);
    },
    handleDeleteCompetitor(i) {
      if (this.competitorList.length == 0) {
        return;
      }
      this.competitorList.splice(i, 1);
    },
    hdnaleAddCompetitorUrl() {
      if (this.comUrlList.length == 5) {
        return alert("최대 5개 까지만 url을 등록 할수 있습니다.");
      }
      let data = {
        competitorUrl: "",
      };
      this.comUrlList.push(data);
    },
    handleMinusCompetitorUrl(i) {
      if (this.comUrlList.length == 0) {
        return;
      }
      this.comUrlList.splice(i, 1);
    },
    getProductInfo() {
      fetchProductList().then((res) => {
        if (res.status == 200) {
          this.productList = res.data.data;
          this.competitorList =
            this.productList.length > 0
              ? this.productList[0].competitorList
              : [];
          this.asin =
            this.productList.length > 0 ? this.productList[0].asin : "";
          this.productName =
            this.productList.length > 0 ? this.productList[0].productName : "";
        }
      });
    },
    Loading() {
      this.$store.dispatch("LOADING", true);
    },
    submit() {
      if (this.type == "amazon") {
        if (this.asin == "") {
          return alert("asin을 입력해 주세요");
        } else if (this.productName == "") {
          return alert("제품명을 입력해 주세요");
        }
      }

      let data = {
        type: this.type,
        asin: this.asin,
        productName: this.productName,
        isCompetitor: false,
      };
      this.$store.dispatch("LOADING", true);

      createProduct(data).then(async (res) => {
        if (res.data.status == 200) {
          alert(
            "정상적으로 제품을 등록 하였습니다. 데이터를 불러오는데 몇분의 시간이 거리오니 양해바랍니다."
          );
          await this.getModelData(data);
          this.closeModal();
        } else {
          alert("제품을 다시 등록해주세요.");
        }
      });
    },
    submitForCompetitorData() {
      if (this.productList.length == 0) {
        return alert("자사 제품을 먼저 등록해 주세요");
      }
      if (this.type == "amazon") {
        if (this.competitorList[this.index].asin == "") {
          return alert("asin을 입력해 주세요");
        } else if (this.competitorList[this.index].productName == "") {
          return alert("제품명을 입력해 주세요");
        }
      }

      let data = {
        competitorList: this.competitorList,
      };
      this.$store.dispatch("LOADING", true);
      let id = this.productList[0]._id;
      updateProduct(id, data).then(async (res) => {
        if (res.data.status == 200) {
          alert(
            "정상적으로 경쟁사 제품을 등록 하였습니다. 데이터를 불러오는데 몇분의 시간이 거리오니 양해바랍니다."
          );
          this.closeModal();
          this.getCompetitorModelData();
        } else {
          alert("제품을 다시 등록해주세요.");
        }
      });
    },
    async getCompetitorModelData() {
      let data = {
        type: this.type,
        asin: this.competitorList[this.index].asin,
        productName: this.competitorList[this.index].productName,
        isCompetitor: true,
      };
      createModelData(data).then((res) => {
        if (res.data.status == 200) {
          if (this.index + 1 == this.competitorList.length) {
            this.$store.dispatch("LOADING", false);
            this.closeModal();
            window.document.location.href = window.document.location;
          } else {
            this.index++;
            this.getCompetitorModelData();
          }
        } else {
          this.$store.dispatch("LOADING", false);
          return alert(
            "데이터 처리중 오류가 발생하였습니다 다시 시도해주세요."
          );
        }
      });
    },
    async getModelData(data) {
      // let data = {
      //   review_source: this.type,
      //   asin: this.asin,
      //   productName: this.productName,
      //   competitorList: this.competitorList,
      // };
      createModelData(data).then((res) => {
        this.$store.dispatch("LOADING", false);
        if (res.data.status == 200) {
          // let result = confirm("경쟁사 제품을 등록 하시겠습니까?");
          // if (result == true) {
          //   this.openModal();
          //   this.handleTab("competitor");
          // } else {
          // this.openModal();
          window.document.location.href = window.document.location;
          // }
        } else {
          return alert(
            "데이터 처리중 오류가 발생하였습니다 다시 시도해주세요."
          );
        }
      });
    },
  },
};
</script>
