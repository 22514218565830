var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ReviewDetail modal" }, [
    _c("div", { staticClass: "modal_cover", on: { click: _vm.close } }),
    _c("div", { staticClass: "modal_content" }, [
      _c("div", { staticClass: "m_header" }, [
        _c("div", [_vm._v(_vm._s(_vm.productName) + " 제품 리뷰 상세 데이터")]),
        _c("button", { staticClass: "close", on: { click: _vm.close } }, [
          _c("span", { staticClass: "material-icons" }, [_vm._v(" close ")])
        ])
      ]),
      _c("div", { staticClass: "m_body" }, [
        _c("div", { staticClass: "buttonWrap" }, [
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 1 },
              on: {
                click: function($event) {
                  return _vm.handleTab("one")
                }
              }
            },
            [_vm._v(" General ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 2 },
              on: {
                click: function($event) {
                  return _vm.handleTab("two")
                }
              }
            },
            [_vm._v(" Size ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 3 },
              on: {
                click: function($event) {
                  return _vm.handleTab("three")
                }
              }
            },
            [_vm._v(" Material ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 4 },
              on: {
                click: function($event) {
                  return _vm.handleTab("four")
                }
              }
            },
            [_vm._v(" Quality ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 5 },
              on: {
                click: function($event) {
                  return _vm.handleTab("five")
                }
              }
            },
            [_vm._v(" Color ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 6 },
              on: {
                click: function($event) {
                  return _vm.handleTab("six")
                }
              }
            },
            [_vm._v(" Price ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 7 },
              on: {
                click: function($event) {
                  return _vm.handleTab("seven")
                }
              }
            },
            [_vm._v(" Picture ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 8 },
              on: {
                click: function($event) {
                  return _vm.handleTab("eight")
                }
              }
            },
            [_vm._v(" Style ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 9 },
              on: {
                click: function($event) {
                  return _vm.handleTab("nine")
                }
              }
            },
            [_vm._v(" Brand ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 10 },
              on: {
                click: function($event) {
                  return _vm.handleTab("ten")
                }
              }
            },
            [_vm._v(" Shipping ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 11 },
              on: {
                click: function($event) {
                  return _vm.handleTab("eleven")
                }
              }
            },
            [_vm._v(" Durability ")]
          ),
          _c(
            "button",
            {
              class: { active: _vm.aspectIndex == 12 },
              on: {
                click: function($event) {
                  return _vm.handleTab("twelve")
                }
              }
            },
            [_vm._v(" Problem ")]
          )
        ]),
        _c("div", { staticClass: "reviewList" }, [
          _c("div", [
            _vm.reviewList.filter(function(data) {
              return data.aspect_Index == _vm.aspectIndex
            }).length > 0
              ? _c(
                  "table",
                  { staticClass: "tr6" },
                  [
                    _vm._m(0),
                    _vm._l(
                      _vm.reviewList.filter(function(data) {
                        return data.aspect_Index == _vm.aspectIndex
                      }),
                      function(data, i) {
                        return _c("tr", { key: i }, [
                          _c("td", [_vm._v(_vm._s(i + 1))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(data.review_date)
                                  .format("YYYY.MM.DD")
                              )
                            )
                          ]),
                          _c("td", [_vm._v(_vm._s(data.review_source))]),
                          _c("td", [_vm._v(_vm._s(data.sentence))]),
                          _c("td", [_vm._v(_vm._s(data.rating))]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.ABSA_predict == 0
                                    ? "Negative"
                                    : data.ABSA_predict == 1
                                    ? "Positive"
                                    : data.ABSA_predict == 2
                                    ? "Neutral"
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        ])
                      }
                    )
                  ],
                  2
                )
              : _c("div", { staticClass: "nodata" }, [
                  _vm._v("데이터가 없습니다.")
                ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("번호")]),
      _c("th", [_vm._v("날짜")]),
      _c("th", [_vm._v("리뷰 소스")]),
      _c("th", [_vm._v("리뷰 문장")]),
      _c("th", [_vm._v("별점")]),
      _c("th", [_vm._v("감성분석")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }